<template>
  <div>
    <b-card title="Filter">
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Start & End</label>
          <date-picker
            v-model="dateRange"
            value-type="format"
            format="YYYY-MM-DD"
            type="datetime"
            range
            confirm
            :clearable="false"
            :shortcuts="shortcuts"
            :show-time-panel="showTimeRangePanel"
            @close="handleRangeClose"
          >
            <template v-slot:footer>
              <b-button variant="flat-primary" @click="toggleTimeRangePanel">
                {{ showTimeRangePanel ? 'select date' : 'select time' }}
              </b-button>
            </template>
          </date-picker>
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Type</label>
          <v-select
            v-model="providerFilter"
            :options="providerOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <b-button
            class="mt-2"
            variant="primary"
            @click="query"
          >
            Search
          </b-button>
          <!-- <b-button
            class="mt-2 ml-2"
            variant="outline-primary"
            @click="reset"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <span>Only latest <strong>1000</strong> records will be shown</span> -->
          </b-col>

          <!-- <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="query"
              >
                <span class="text-nowrap">Refresh</span>
              </b-button>
            </div>
          </b-col> -->
        </b-row>
      </div>

      <b-table
        class="position-relative"
        :items="downloadList"
        :per-page="perPage"
        :current-page="currentPage"
        striped
        responsive
        :fields="tableFields"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: Status -->
        <template #cell(type)="data">
          <div>
            {{ formatBankBillTypes(data.item.type) }}
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div>
            <span v-if="data.item.report_url && data.item.report_url !== 'ERROR'">
              <a
                target="_blank"
                :href="data.item.report_url"
              >
                Download
              </a>
            </span>
            <span v-else-if="data.item.report_url">
              Error
            </span>
            <span v-else>
              Processing
            </span>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 mr-3"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BPagination,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import useBankBillsJs from './bankbills'

const {
  fetchBankBillList,
} = useBankBillsJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      downloadList: [],
      dataMeta: {
        from: 1,
        to: 1,
        of: 0,
      },
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
      tableFields: [
        { key: 'create_time', label: 'Create Date(UTC)' },
        { key: 'start', label: 'Start' },
        { key: 'end', label: 'End' },
        { key: 'type', label: 'Type' },
        { key: 'status', label: 'STATUS' },
      ],
      checkDate: '',
      showTimeRangePanel: false,
      dateRange: [],
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const end = moment().subtract(1, 'days').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 7 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(6, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'This Month',
          onClick() {
            const end = moment().endOf('month').endOf('day')
              .toDate()
            const start = moment().startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 3 Months',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(3, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
      ],
      providerFilter: '',
      providerOptions: [
        { label: 'All', value: '' },
        { label: 'BS2-PIX', value: '0' },
        { label: 'BS2-PIX-Luxpag', value: '3' },
        { label: 'AME-WALLET', value: '1' },
        { label: 'BRADESCO-PIX', value: '4' },
        { label: 'BS2-Boleto', value: '2' },
        { label: 'BS2-Boleto-TransferSmile', value: '8' },
        { label: 'RENDIMENTO-PIX', value: '5' },
        { label: 'EtPay', value: '6' },
        { label: 'PagoEfectivo', value: '7' },
        { label: 'SPEI-STP', value: '9' },
        { label: 'StarkBank-PIX', value: '10' },
      ],
    }
  },
  watch: {
    dateRange: function formatWithTz(object) {
      const startTime = object[0]
      const endTime = object[1]
      const startTzTime = moment.tz(startTime, this.tzFilter)
      const endTzTime = moment.tz(endTime, this.tzFilter)
      const startTimeStr = startTzTime.clone().tz('UTC').format('YYYY-MM-DD')
      const endTimeStr = endTzTime.clone().tz('UTC').format('YYYY-MM-DD')

      const dateArray = new Array(0)
      dateArray.push(startTimeStr, endTimeStr)
      this.$emit('update:dateRange', dateArray)
    },
    currentPage() {
      this.recalMetaData()
    },
  },
  created() {
    this.dateRange = [moment().startOf('month').startOf('day').format('YYYY-MM-DD'), moment().endOf('day').format('YYYY-MM-DD')]
    this.query()
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    recalMetaData() {
      this.dataMeta.from = this.currentPage * this.perPage - (this.perPage - 1)
      this.dataMeta.to = this.totalRecords - this.currentPage * this.perPage > 0 ? this.currentPage * this.perPage : this.totalRecords
      this.dataMeta.of = this.totalRecords
    },
    formatBankBillTypes(type) {
      switch (type) {
        case '0':
          return 'BS2-PIX'
        case '1':
          return 'AME-WALLET'
        case '2':
          return 'BS2-Boleto'
        case '3':
          return 'BS2-PIX-Luxpag'
        case '4':
          return 'BRADESCO-PIX'
        case '5':
          return 'RENDIMENTO-PIX'
        case '6':
          return 'EtPay'
        case '7':
          return 'PagoEfectivo'
        case '8':
          return 'BS2-Boleto-TransferSmile'
        case '9':
          return 'SPEI-STP'
        case '10':
          return 'StarkBank-PIX'
        default:
          return 'N/A'
      }
    },
    formatStatus(status) {
      switch (status) {
        case 0:
          return 'Processing'
        case 1:
          return 'Error'
        case 2:
          return 'Download'
        default:
          return 'N/A'
      }
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel
    },
    handleRangeClose() {
      this.showTimeRangePanel = false
    },
    reset() {
      this.checkDate = ''
      this.providerFilter = ''
    },
    query() {
      const [start, end] = this.dateRange
      const params = {
        start_date: start,
        end_date: end,
        bill_type: this.providerFilter,
      }
      fetchBankBillList(
        params,
        data => {
          this.downloadList = data.list
          this.totalRecords = data.total
          this.recalMetaData()
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
